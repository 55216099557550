<template>
    <item-list-view
        :api="api"
        :headers="headers"
        id-key="uuid"
        title="Уведомления"
        :allowed-search="false"
        :allowed-create="true"
        :allowed-remove="true"
        :disable-pagination="true"
        :create-route="{ name: 'InformerNotificationEdit', params: { id: 'new' }}"
        update-route="InformerNotificationEdit"
        remove-title="Удалить"
        remove-message="Вы действительно хотите удалить?"
    >
        <template v-slot:item.active="{ item }">
            <v-chip small label dark :color="item.active ? 'green' : 'red'">
                {{ item.active ? 'Да' : 'Нет' }}
            </v-chip>
        </template>
        <template v-slot:item.location="{ item }">
            {{ locations[item.location] }}
        </template>
        <template v-slot:item.type="{ item }">
            {{ types[item.type] }}
        </template>
        <template v-slot:item.message="{ item }">
            {{ item.message.replace(/<\/?[^>]+(>|$)/g, "") }}
        </template>
    </item-list-view>
</template>

<script>
import api from '../../services/informer'
import ItemListView from '../../components/ItemListView'
import { Types, Locations } from './constants'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Место', value: 'location' },
      { text: 'Тип', value: 'type' },
      { text: 'Сообщение', value: 'message' },
      { text: 'Активна', value: 'active' },
      { text: '', value: 'action' }
    ],
    locations: Locations,
    types: Types
  })
}
</script>
