var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('item-list-view',{attrs:{"api":_vm.api,"headers":_vm.headers,"id-key":"uuid","title":"Уведомления","allowed-search":false,"allowed-create":true,"allowed-remove":true,"disable-pagination":true,"create-route":{ name: 'InformerNotificationEdit', params: { id: 'new' }},"update-route":"InformerNotificationEdit","remove-title":"Удалить","remove-message":"Вы действительно хотите удалить?"},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":item.active ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.active ? 'Да' : 'Нет')+" ")])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.locations[item.location])+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.types[item.type])+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message.replace(/<\/?[^>]+(>|$)/g, ""))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }